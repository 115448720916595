import * as React from 'react';
import { Box, Container, Typography } from '@mui/material';

import { makeClasses } from 'styles';

const useStyles = makeClasses({
  bg: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: -20,
    backgroundImage: 'url(/image/error_cat.gif)',
    backgroundSize: '100%',
    minHeight: {
      xl: '1300px',
      lg: '900px',
      md: '800px',
      sm: '650px',
      xs: '800px',
    },
  },
  innerBox: {
    minHeight: {
      xl: '1000px',
      lg: '600px',
      md: '500px',
      sm: '350px',
      xs: '500px',
    },
    display: 'grid',
    placeItems: 'center',
  },
  genericText: {
    color: 'primary.main',
  },
});

export type ErrorWrapperProps = {
  children: React.ReactNode;
  blankBg?: boolean;
};
export const ErrorWrapper = (props: ErrorWrapperProps): JSX.Element => {
  const { children, blankBg } = props;
  const classes = useStyles;

  return (
    <Container maxWidth="md" sx={classes.innerBox}>
      {!blankBg ? <Box sx={classes.bg} /> : null}
      {children}
    </Container>
  );
};

export type GenericErrorProps = {
  message?: string;
  children?: React.ReactNode;
};
export function GenericError(props: GenericErrorProps): JSX.Element {
  const { message, children } = props;
  const classes = useStyles;

  return (
    <ErrorWrapper>
      {message ? (
        <Typography variant="h1" sx={classes.genericText}>
          {message}
        </Typography>
      ) : null}
      {children}
    </ErrorWrapper>
  );
}

import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';

export type CodeErrorProps = {
  statusCode: number;
  textColor?: string;
};

export const CodeError = (props: CodeErrorProps): JSX.Element => {
  const { statusCode, textColor = 'white' } = props;

  const sxAww = {
    color: textColor,
    fontFamily: 'fontFamilySerif',
    fontSize: {
      xs: '3rem',
      md: '4.5rem',
    },
    paddingRight: {
      xs: 0,
      sm: 3,
    },
  };
  const sxCode = {
    color: textColor,
    fontFamily: 'fontFamilySerif',
    fontSize: {
      xs: '5rem',
      md: '10rem',
    },
    paddingLeft: 3,
    paddingRight: {
      xs: 0,
      sm: null,
    },
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Typography component="span" sx={sxAww}>
            Awww :(
          </Typography>
          <Typography component="span" align="right" sx={sxCode}>
            {statusCode}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ display: { xs: 'block', sm: 'none' }, textAlign: 'right' }}
      >
        <Typography sx={sxAww}>Awww :(</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          textAlign: {
            xs: 'right',
            sm: 'left',
          },
          display: { xs: 'block', sm: 'none' },
        }}
      >
        <Typography align="right" sx={sxCode}>
          {statusCode}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          color={textColor}
          variant="h4"
          align="right"
          sx={{ fontFamily: 'fontFamilySansSerif' }}
        >
          Ran out of energy ???
        </Typography>
        <Typography
          color={textColor}
          variant="h5"
          align="right"
          sx={{ fontFamily: 'fontFamilySansSerif' }}
        >
          Grab a coffee and try again later.
        </Typography>
      </Grid>
    </Grid>
  );
};

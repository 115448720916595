import * as React from 'react';

import { ErrorWrapper, CodeError } from 'components/shared';

export default function IndexPage(): JSX.Element {
  return (
    <ErrorWrapper>
      <CodeError statusCode={404} />
    </ErrorWrapper>
  );
}
